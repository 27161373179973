import { useQuery, gql } from '@apollo/client';
import { Maybe, Question, SurveyQuestionSearch } from '__generated__/graphql';

export const GET_SURVEY_QUESTIONS = gql`
  query GetSurveyQuestions($query: SurveyQuestionSearch) {
    GetSurveyQuestions(query: $query) {
      personality {
        id
        pic
        title
        subtitle
        text
        factor
        polarity
      }
      interests {
        id
        pic
        title
        subtitle
        text
        factor
        polarity
      }
      values {
        id
        pic
        title
        subtitle
        text
        factor
        polarity
      }
      customer {
        id
        pic
        title
        subtitle
        text
        factor
        polarity
        jobRoleId
      }
    }
  }
`;

interface GetSurveyQuestionsResponse {
  GetSurveyQuestions: Maybe<Question>;
}

const useGetQuestions = (query: SurveyQuestionSearch) => {
  const { loading, data } = useQuery<GetSurveyQuestionsResponse>(GET_SURVEY_QUESTIONS, {
    variables: {
      skip: !query.companyId,
      query: {
        ...query,
      },
    },
  });
  return {
    questionLoading: loading,
    questions: data?.GetSurveyQuestions || {},
  };
};

export { useGetQuestions };
