import { personalityDimensions, valuesDimensions, vocationalInterests } from './constants';

export type ComputedCategoryTraits = Record<string, number | null>;

export type ResultsInfoContainerDims = {
  width: number;
  height: number;
};

export type InfoBoxDescription = string[] | { positive: string[]; negative: string[] };

export enum TraitCategory {
  PERSONALITY = 'personality',
  INTERESTS = 'interests',
  VALUES = 'values',
}

type TraitCategoryValues = {
  [TraitCategory.PERSONALITY]: string[];
  [TraitCategory.INTERESTS]: string[];
  [TraitCategory.VALUES]: string[];
};

export const CATEGORY_TRAITS: TraitCategoryValues = {
  [TraitCategory.PERSONALITY]: personalityDimensions.map((dimension) => dimension.key),
  [TraitCategory.INTERESTS]: vocationalInterests.map((dimension) => dimension.key),
  [TraitCategory.VALUES]: valuesDimensions.map((dimension) => dimension.key),
};
