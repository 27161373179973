import React, { Suspense } from 'react';
import { useNextStep } from 'hooks/useStep';
import { useGetQuestions } from 'api/hooks/useSurveyQuestions';
import { AnswerTimes, AnswersItem, Maybe, QuestionItem } from '__generated__/graphql';
import Deck from 'components/Deck';
import Loading from '../components/Loading';
import { useUser } from '../context/userContext';

interface QuestionsProps {
  id: keyof {
    personality: Maybe<QuestionItem>[];
    interests: Maybe<QuestionItem>[];
    values: Maybe<QuestionItem>[];
    customer: Maybe<QuestionItem>[];
  };
  title?: string;
  icon?: string;
}
const Questions = ({ id, title, icon }: QuestionsProps) => {
  const { company, companyLoading, answers, answerTimes, saveUserAnswers, userLoading, jobRoleId } = useUser();
  const { questions, questionLoading } = useGetQuestions({ companyId: company?.id || '', jobRoleId });

  const { nextStep } = useNextStep();

  const storeAnswers = (newAnswers: AnswersItem, newAnswerTimes: AnswerTimes) => {
    const storedAnswers = { ...answers };
    const storedTimes = { ...answerTimes };

    const answerKeys = Object.keys(newAnswers) as (keyof AnswersItem)[];
    answerKeys.forEach((key) => {
      storedAnswers[key] = newAnswers[key];
    });

    const keys = Object.keys(newAnswerTimes) as (keyof typeof newAnswerTimes)[];
    keys.forEach((key) => {
      storedTimes[key] = newAnswerTimes[key];
    });
    saveUserAnswers(storedAnswers, storedTimes);
    nextStep();
  };

  if (companyLoading && questionLoading && userLoading) return <Loading />;

  return (
    <div className="h-5/6 flex flex-col justify-between items-center text-white text-center">
      <Suspense fallback={<Loading />}>
        <img alt="questions-icon" className="w-14 h-14 text-white m-4" src={icon} />
        <div className="text-white text-l sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">{title}</div>
        {questions[id]?.length === 0 ? (
          <div className="loading">
            <p>Sorry no questions have loaded for this session</p>
          </div>
        ) : (
          <Deck key={`${id}Cards`} id={`${id}Cards`} questions={questions[id] || []} storeAnswers={storeAnswers} instructionsActiveInitial={1} />
        )}
      </Suspense>
    </div>
  );
};

export default Questions;
