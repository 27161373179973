import React, { createContext, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { useGetCompany } from 'api/hooks/useCompany';
import { AnswerTimes, AnswerTimesInput, AnswersItem, AnswersItemInput, Company, Maybe, Profile, ProfileInput } from '__generated__/graphql';
import { useSaveAnswers, useGetSurveyAnswers } from '../api/hooks/useSurveyAnswers';

const defaultProps = {
  answers: {
    c4: null,
    c7: null,
    c10: null,
    c13: null,
    c14: null,
    c16: null,
    c17: null,
    c19: null,
    c22: null,
    c23: null,
    c24: null,
    c24a: null,
    c25: null,
    dt4: null,
    hi3: null,
    ip1: null,
    ip2: null,
    ip3: null,
    ip4: null,
    ip5: null,
    ip6: null,
    ip7: null,
    ip8: null,
    ip9: null,
    ip10: null,
    ip11: null,
    ip12: null,
    ip13: null,
    ip14: null,
    ip15: null,
    ip16: null,
    ip17: null,
    ip18: null,
    ip19: null,
    ip20: null,
    ip21: null,
    ip22: null,
    ip23: null,
    ip24: null,
    ip25: null,
    ip26: null,
    ip27: null,
    ip28: null,
    ip29: null,
    ip30: null,
    p1a: null,
    p2: null,
    p3: null,
    p4: null,
    p5: null,
    p6: null,
    p7: null,
    p8: null,
    p9: null,
    p10: null,
    p11: null,
    p12: null,
    p13: null,
    p14: null,
    p15a: null,
    p16a: null,
    p17: null,
    p18: null,
    p19a: null,
    p20b: null,
    yv2: null,
    yv4: null,
    yv5: null,
    yv8: null,
    yv12: null,
    yv21a: null,
    yv23: null,
    yv26: null,
    yv28: null,
    yv29: null,
    yv32: null,
    yv33: null,
    yv34: null,
    yv37: null,
    yv38: null,
  },
  answerTimes: {
    c4: null,
    c7: null,
    c10: null,
    c13: null,
    c14: null,
    c16: null,
    c17: null,
    c19: null,
    c22: null,
    c23: null,
    c24: null,
    c24a: null,
    c25: null,
    dt4: null,
    hi3: null,
    ip1: null,
    ip2: null,
    ip3: null,
    ip4: null,
    ip5: null,
    ip6: null,
    ip7: null,
    ip8: null,
    ip9: null,
    ip10: null,
    ip11: null,
    ip12: null,
    ip13: null,
    ip14: null,
    ip15: null,
    ip16: null,
    ip17: null,
    ip18: null,
    ip19: null,
    ip20: null,
    ip21: null,
    ip22: null,
    ip23: null,
    ip24: null,
    ip25: null,
    ip26: null,
    ip27: null,
    ip28: null,
    ip29: null,
    ip30: null,
    p1a: null,
    p2: null,
    p3: null,
    p4: null,
    p5: null,
    p6: null,
    p7: null,
    p8: null,
    p9: null,
    p10: null,
    p11: null,
    p12: null,
    p13: null,
    p14: null,
    p15a: null,
    p16a: null,
    p17: null,
    p18: null,
    p19a: null,
    p20b: null,
    yv2: null,
    yv4: null,
    yv5: null,
    yv8: null,
    yv12: null,
    yv21a: null,
    yv23: null,
    yv26: null,
    yv28: null,
    yv29: null,
    yv32: null,
    yv33: null,
    yv34: null,
    yv37: null,
    yv38: null,
  },
  profile: { companyId: '' } as Profile,
  questions: { personality: [], interests: [], values: [], customer: [] },
  saveUserAnswers: () => undefined,
  saveProfile: () => undefined,
  saveCompleteProcess: () => undefined,
  userLoading: false,
  questionLoading: false,
  saveAnswersLoading: false,
  company: null,
  companyLoading: false,
  jobRoleId: '',
  clear: () => undefined,
};

export interface UserContextProps {
  answers: AnswersItem;
  answerTimes: AnswerTimes;
  profile: Maybe<Profile>;
  saveUserAnswers: (answers: AnswersItemInput, answerTimes: AnswerTimesInput) => void;
  saveProfile: (profile: ProfileInput) => void;
  saveCompleteProcess: () => void;
  saveAnswersLoading: boolean;
  userLoading: boolean;
  company: Company | null | undefined;
  companyLoading: boolean;
  jobRoleId: string;
  clear: () => void;
}

type UserProviderProps = {
  children: React.ReactNode;
};

const UserContext = createContext<UserContextProps>(defaultProps);

const UserProvider = ({ children }: UserProviderProps) => {
  const { companyId } = useParams();
  const id = localStorage.getItem('id') || uuidv4();
  const jobRoleId = localStorage.getItem('jobRoleId') || '';
  localStorage.setItem('id', id);

  const { company, companyLoading } = useGetCompany({ id: companyId });
  const { answers, answerTimes, profile, userLoading } = useGetSurveyAnswers(id, companyId || '');
  const { saveProfile, saveUserAnswers, saveCompleteProcess, saveAnswersLoading } = useSaveAnswers(id, companyId || '');

  const clear = () => {
    localStorage.clear();
  };

  return (
    <UserContext.Provider
      value={{
        answers,
        answerTimes,
        profile,
        saveUserAnswers,
        saveProfile,
        saveCompleteProcess,
        userLoading,
        saveAnswersLoading,
        company,
        companyLoading,
        jobRoleId,
        clear,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = (): UserContextProps => {
  const context = useContext(UserContext);
  return context;
};

export { UserProvider, useUser };
