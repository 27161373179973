export const RESULTS_INFO_CONTAINER_DIMS = { width: 620, height: 480 };

export const INFO_LIST_COLORS = ['#489982', '#d3f1f1', '#A36A00', '#F8E8B1', '#5773B3', '#9C56BE', '#F05454'];

export const factorIdMap: Record<string, string[]> = {
  agreeableness: ['p7', 'p12', 'p17', 'p2', 'c10', 'c14', 'c16', 'c17', 'c22', 'yv21a'],
  artistic: ['ip14', 'ip15', 'ip13', 'ip11', 'ip12'],
  commitment: ['c25', 'yv32', 'yv33', 'yv34'],
  conscientiousness: ['p8', 'p13', 'p18', 'p3', 'yv4', 'yv5', 'yv23'],
  conventional: ['ip28', 'ip26', 'ip30', 'ip29', 'ip27'],
  enterprising: ['ip24', 'ip21', 'ip22', 'ip23', 'ip25'],
  excellence: ['yv2', 'yv8', 'yv37'],
  extraversion: ['p11', 'p6', 'p16a', 'p1a', 'c4', 'hi3'],
  hierarchy: ['yv12', 'yv29'],
  intellect_imagination: ['p15a', 'p5', 'p20b', 'p10'],
  investigative: ['ip8', 'ip10', 'ip7', 'ip6', 'ip9'],
  neuroticism: ['p9', 'p4', 'p19a', 'p14'],
  realistic: ['ip1', 'ip3', 'ip2', 'ip5', 'ip4', 'c19'],
  resilience: ['c23', 'c24a', 'c24', 'yv38'],
  social: ['ip19', 'ip20', 'ip16', 'ip17', 'ip18', 'c7'],
  team_player: ['c13', 'dt4', 'yv26', 'yv28'],
};

export const personalityDimensions = [
  {
    key: 'extraversion',
    name: 'Extraversion',
    description: {
      positive: [
        'You are outgoing and talkative, thriving in social situations where you can connect with others.',
        'Energy comes from being around people, and you enjoy being the center of attention when the moment calls for it.',
        'Expressing yourself boldly, whether through conversation, humor, or even a bit of singing, feels natural to you.',
      ],
      negative: [
        "You're happy with your own company and thoughts, finding peace in solitude rather than seeking social stimulation.",
        'Socializing can be draining, and you prefer deep one-on-one conversations over large, loud gatherings.',
        'Some might call you reserved or quiet, but you see it as being thoughtful and selective about when you engage.',
      ],
    },
  },
  {
    key: 'intellect_imagination',
    name: 'Openness to Experience',
    description: {
      positive: [
        'You are deeply curious and love exploring new ideas, perspectives, and experiences.',
        'Routine and predictability bore you—variety, creativity, and novelty keep you engaged.',
        'Your imagination knows no bounds, and you enjoy thinking outside the box to come up with innovative solutions.',
      ],
      negative: [
        'You prefer familiar approaches and time-tested methods over experimental or abstract ideas.',
        "Change for the sake of change isn't appealing—you appreciate stability and reliability in life.",
        "Some might call you 'traditional' or 'set in your ways,' but you just know what works and see no need to fix what isn't broken.",
      ],
    },
  },
  {
    key: 'agreeableness',
    name: 'Friendliness',
    description: {
      positive: [
        'You genuinely enjoy helping others and believe in the importance of kindness and trust.',
        'Seeing people get along makes you happy, and you try to avoid unnecessary conflict whenever possible.',
        'Collaboration and cooperation come naturally—you’re the kind of person others feel comfortable turning to for support.',
      ],
      negative: [
        'Competitiveness drives you, and you don’t always see the need to go along with the group just for the sake of harmony.',
        'You speak your mind, even when it ruffles feathers—debate and disagreement don’t bother you as long as the point is made.',
        'Some might call you blunt or argumentative, but you see it as standing your ground and being honest about what you think.',
      ],
    },
  },
  {
    key: 'conscientiousness',
    name: 'Conscientiousness',
    description: {
      positive: [
        'You are organized, reliable, and like to plan ahead to ensure things go smoothly.',
        'A strong sense of discipline and responsibility drives you to follow through on commitments.',
        "Predictability and structure bring you comfort—you'd rather have a clear plan than be caught off guard by surprises.",
      ],
      negative: [
        'Spontaneity is your style—you prefer to go with the flow rather than stick to rigid plans.',
        'Rules and routines can feel restrictive, and you’re comfortable making last-minute decisions when needed.',
        'Some may see you as disorganized or inconsistent, but you believe flexibility and adaptability are just as valuable as structure.',
      ],
    },
  },
  {
    key: 'neuroticism',
    name: 'Neuroticism',
    description: {
      positive: [
        'You are emotionally dynamic, experiencing both highs and lows with great intensity.',
        'Your passion and sensitivity make you deeply aware of your emotions and those of others.',
        'You care deeply about things, which can sometimes lead to stress or overthinking—but it also means you’re engaged and invested.',
      ],
      negative: [
        'You’re naturally calm and level-headed, taking life’s ups and downs in stride without excessive worry.',
        'Rarely rattled by stress, you tend to keep a cool head even in difficult situations.',
        'Some might think of you as emotionally detached, but you just prefer not to get caught up in unnecessary drama.',
      ],
    },
  },
];

export const vocationalInterests = [
  {
    key: 'artistic',
    name: 'Artistic',
    description: {
      positive: [
        'You are highly creative and enjoy expressing yourself through art, music, writing, or design.',
        'Thinking outside the box and working without rigid rules comes naturally to you.',
        'You thrive in environments that encourage originality, imagination, and self-expression.',
      ],
      negative: [
        'You prefer structured work with clear guidelines rather than open-ended creative tasks.',
        'Artistic pursuits don’t particularly interest you, and you favor logical or practical work instead.',
        'You find comfort in routines and step-by-step processes rather than spontaneous or abstract thinking.',
      ],
    },
  },
  {
    key: 'conventional',
    name: 'Conventional',
    description: {
      positive: [
        'You appreciate structure, organization, and following clear rules in your work.',
        'Efficiency and reliability are important to you, and you enjoy ensuring tasks are completed accurately.',
        'Routine and order help you stay focused, and you prefer work with established procedures.',
      ],
      negative: [
        'You dislike rigid structures and prefer flexibility and innovation over routine tasks.',
        'Strict rules and processes feel restrictive, and you enjoy thinking outside the box.',
        "Following repetitive procedures doesn't appeal to you, as you prefer creative or unstructured work.",
      ],
    },
  },
  {
    key: 'enterprising',
    name: 'Enterprising',
    description: {
      positive: [
        'You are ambitious, enjoy taking charge, and thrive in leadership roles.',
        'Taking risks for potential rewards excites you, and you’re motivated by competition and success.',
        'You enjoy persuading and influencing others, making you a natural leader or entrepreneur.',
      ],
      negative: [
        'You prefer stability over risk and would rather follow than lead.',
        'Competitive or high-pressure environments feel overwhelming rather than exciting.',
        'Sales, persuasion, or business leadership don’t interest you as much as other career paths.',
      ],
    },
  },
  {
    key: 'investigative',
    name: 'Investigative',
    description: {
      positive: [
        'You love exploring ideas, analyzing problems, and searching for answers.',
        'Solving complex issues through logic, research, and critical thinking excites you.',
        'You enjoy intellectual challenges and prefer roles that require deep thinking and investigation.',
      ],
      negative: [
        'You prefer action over analysis and dislike spending too much time researching.',
        'Thinking deeply about abstract problems doesn’t appeal to you as much as hands-on work.',
        'You enjoy practical, straightforward tasks rather than theoretical or analytical work.',
      ],
    },
  },
  {
    key: 'realistic',
    name: 'Realistic',
    description: {
      positive: [
        'You prefer hands-on, physical work over sitting at a desk all day.',
        'Working with tools, machinery, or real-world materials like plants and animals excites you.',
        'You enjoy practical, tangible tasks and like seeing immediate results from your efforts.',
      ],
      negative: [
        'You are more comfortable working with ideas, data, or people rather than physical tasks.',
        'Hands-on labor or working with tools and machinery doesn’t appeal to you.',
        'You prefer intellectual or creative work over practical, real-world problem-solving.',
      ],
    },
  },
  {
    key: 'social',
    name: 'Social',
    description: {
      positive: [
        'You love interacting with people and find fulfillment in helping, teaching, or supporting others.',
        'Building relationships and working in team-oriented environments excites you.',
        'You are naturally empathetic and enjoy roles where you can make a positive impact on others.',
      ],
      negative: [
        'You prefer working alone rather than in social or team-based environments.',
        'Helping or teaching others isn’t your primary interest; you’d rather focus on independent tasks.',
        'Interacting with people all day feels draining, and you prefer work that requires less social engagement.',
      ],
    },
  },
];

export const valuesDimensions = [
  {
    key: 'commitment',
    name: 'Commitment',
    description: {
      positive: [
        'You are highly dedicated, taking ownership of your work and ensuring tasks are completed with care.',
        'Going the extra mile comes naturally, as you proactively seek ways to contribute beyond what is required.',
        'A strong sense of responsibility drives you to stay focused and persevere, even when challenges arise.',
      ],
      negative: [
        'You prefer to do only what is required rather than take on additional responsibilities.',
        'Maintaining flexibility is more important to you than deeply committing to long-term tasks or projects.',
        'A strong attachment to work isn’t a priority, as you value a more relaxed and adaptable approach.',
      ],
    },
  },
  {
    key: 'excellence',
    name: 'Excellence',
    description: {
      positive: [
        'You hold yourself to high standards and take pride in delivering outstanding work.',
        'Attention to detail matters, and you enjoy refining and improving your work to achieve the best possible outcome.',
        'Striving for excellence motivates you, pushing you to exceed expectations and inspire those around you.',
      ],
      negative: [
        'You prefer to get things done rather than focus on perfecting every detail.',
        'High standards can feel unnecessary, as you prioritize practicality over striving for top-tier results.',
        'Rather than refining work extensively, you are comfortable with “good enough” as long as tasks are completed efficiently.',
      ],
    },
  },
  {
    key: 'hierarchy',
    name: 'Hierarchy',
    description: {
      positive: [
        'You appreciate well-defined structures and roles, finding comfort in clear guidance and expectations.',
        'Having a clear chain of command provides stability and direction, helping you navigate tasks effectively.',
        'Following instructions and knowing your responsibilities allows you to work efficiently without unnecessary uncertainty.',
      ],
      negative: [
        'You prefer a flat hierarchy where everyone has equal say, rather than following a strict chain of command.',
        'Authority figures don’t strongly influence your work style, as you value autonomy and personal decision-making.',
        'Strict structures feel unnecessary to you, and you prefer an environment where roles are fluid and adaptable.',
      ],
    },
  },
  {
    key: 'resilience',
    name: 'Resilience',
    description: {
      positive: [
        'You adapt well to challenges, staying positive and finding ways to overcome obstacles.',
        'Difficult situations don’t easily shake you, as you remain calm and focus on problem-solving.',
        'Persistence helps you push through setbacks, ensuring that challenges become learning experiences rather than roadblocks.',
      ],
      negative: [
        'You find it difficult to stay composed in the face of stress and setbacks.',
        'Challenges can feel overwhelming rather than motivating, making it harder to bounce back quickly.',
        'Rather than pushing through adversity, you prefer to step back and avoid overly stressful situations when possible.',
      ],
    },
  },
  {
    key: 'team_player',
    name: 'Team Player',
    description: {
      positive: [
        'You enjoy working with others, contributing ideas, and fostering a spirit of collaboration.',
        'Supporting teammates and valuing cooperation come naturally, making group success a priority for you.',
        'A sense of camaraderie and shared achievement is fulfilling, motivating you to contribute generously to team efforts.',
      ],
      negative: [
        'You prefer working independently and find collaboration less appealing than handling tasks on your own.',
        'Group work feels inefficient at times, and you are more comfortable relying on your own skills and judgment.',
        'Rather than prioritizing teamwork, you focus on achieving your own goals without the need for collective input.',
      ],
    },
  },
];

export const resultsDimensions = [...personalityDimensions, ...vocationalInterests, ...valuesDimensions];
