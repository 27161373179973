import { factorIdMap } from 'pages/ResultsOverview/constants';
import { AnswersItem, Maybe, Question, QuestionItem } from './__generated__/graphql';

export const allQuestionsAnswered = (questions: Question, answers: AnswersItem) => {
  const personality = checkQuestionsAnswered(questions?.personality || [], answers);
  const interests = checkQuestionsAnswered(questions?.interests || [], answers);
  const values = checkQuestionsAnswered(questions?.values || [], answers);
  const customer = checkQuestionsAnswered(questions?.customer || [], answers);
  return personality && interests && values && customer;
};

export const checkQuestionsAnswered = (questions: Maybe<Maybe<QuestionItem>[] | undefined>, answers: Maybe<AnswersItem>) => {
  if (questions?.length === 0 && answers && Object.values(answers).length === 0) return false;

  const questionIds = questions?.map((question) => question?.id);
  const checkAllKeys = questionIds?.every((id: Maybe<string> | undefined) => {
    if (answers && id) {
      return isBoolean(answers[id as keyof AnswersItem]);
    } else return false;
  });
  return checkAllKeys;
};

export const isAnswerEmpty = (obj: Maybe<AnswersItem>) => {
  if (!obj) {
    return true;
  } else {
    return Object.keys(obj).length === 0;
  }
};

export const isBoolean = (val: string | boolean | null | undefined) => {
  return val === false || val === true;
};

/**
 * This method computes the mean over all the provided answer IDs for which answers can
 * be found in the answers object.
 * @param factor the name of the factor we're computing the mean for.
 * @param answers the object containing the answers.
 * @param questions the original questions object, which contains the polarity we need.
 */
export const meanOf = (factor: string, answers: Maybe<AnswersItem>, questions: Maybe<QuestionItem>[]) => {
  const factorIds = factorIdMap[factor];
  if (!factorIds) {
    return null;
  }

  const answerIds = questions.filter((q) => q?.id && factorIds.includes(q.id)).map((q) => q?.id);

  if (answerIds.length === 0) return null;

  if (!answers || Object.values(answers).every((v) => !v)) {
    return null;
  }

  const accumulatedValue = answerIds.reduce((acc, currId) => {
    let multiplier: Maybe<number> | undefined = 0;
    const currQuestion = questions.filter((q) => q?.id === currId);
    if (currQuestion.length > 0) {
      multiplier = currQuestion[0]?.polarity;
    } else {
      console.log('no polarity for question found!', currId);
    }

    let currentMultipliedValue = 0;
    if (answers && currId && multiplier && currId in answers) {
      const currentAnswer = answers[currId as keyof AnswersItem];
      const currentInt = currentAnswer ? 1 : -1;
      currentMultipliedValue = currentInt * multiplier;
    }
    return acc + currentMultipliedValue;
  }, 0);

  const minusOnePlusOne = accumulatedValue / answerIds.length;
  const percentage = ((minusOnePlusOne + 1) / 2) * 100;
  return percentage;
};

export const capitalizeTitle = (str: string): string => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
