import { useState } from 'react';
import Loading from 'components/Loading';
import { useNextStep } from 'hooks/useStep';
import { useUser } from '../context/userContext';

const ResultsIntro = () => {
  const [completeProcess, setCompleteProcess] = useState(false);
  const { saveCompleteProcess, saveAnswersLoading } = useUser();
  const { nextStep } = useNextStep();

  const onComplete = () => {
    saveCompleteProcess();
    setCompleteProcess(true);
  };

  if (saveAnswersLoading) {
    return (
      <div className="flex flex-col items-center justify-center gap-20 text-center text-white h-5/6">
        <div className="mb-1 text-xl font-semibold text-white sm:text-display-sm leading-xl sm:leading-display-sm">
          Please wait while we process your results
        </div>
        <p className="text-white">Thanks for waiting.</p>
        <Loading isComponent={true} />
      </div>
    );
  }

  if (completeProcess) {
    nextStep();
  }

  return (
    <div className="flex flex-col items-center justify-center gap-20 text-center text-white h-5/6">
      {!saveAnswersLoading && !completeProcess ? (
        <>
          <div className="ps-3">
            <div className="mb-1 text-xl font-semibold text-white sm:text-display-sm leading-xl sm:leading-display-sm">Almost done</div>
            <p className="text-white">Thanks for taking our test!</p>
          </div>
          <img className="w-20 h-20" src="/icon/puzzle_head.png" alt="puzzle head" />
          <p className="text-white">Please click submit to send your results</p>
          <button className="p-2 font-semibold text-black uppercase bg-yellow-500 rounded-lg text-md " type="button" onClick={() => onComplete()}>
            Submit
          </button>
        </>
      ) : null}
    </div>
  );
};

export default ResultsIntro;
