import { useEffect, useState } from 'react';
import { useNextStep } from 'hooks/useStep';
import { useGetQuestions } from 'api/hooks/useSurveyQuestions';
import { LeftOffDialog } from './LeftOffDialog';
import { Carousel } from '../../components/Carousel';
import { useUser } from '../../context/userContext';
import { checkQuestionsAnswered, allQuestionsAnswered, isAnswerEmpty } from '../../utils';
import Loading from '../../components/Loading';
import { AnswersItem, Maybe, Question } from '../../__generated__/graphql';

const whereLeftOff = (allAnswers: boolean | undefined, questions: Maybe<Question>, answers: Maybe<AnswersItem>, companyId: string) => {
  if (!isAnswerEmpty(answers)) {
    if (allAnswers) {
      return `/${companyId}/results-intro`;
    }
    if (!checkQuestionsAnswered(questions?.customer, answers)) {
      return `/${companyId}/questions/customer`;
    }
    if (!checkQuestionsAnswered(questions?.values, answers)) {
      return `/${companyId}/questions/values`;
    }
    if (!checkQuestionsAnswered(questions?.interests, answers)) {
      return `/${companyId}/questions/interests`;
    }
    if (!checkQuestionsAnswered(questions?.personality, answers)) {
      return `/${companyId}/questions/personality`;
    }
  }
};

const AppInstructions = () => {
  const { nextStep } = useNextStep();
  const carousel: { image: string; caption?: string }[] = [
    {
      image: '/companyLogo.png',
      caption:
        // eslint-disable-next-line max-len
        'Welcome to TeamFit, a simple values and personality survey. It only takes 5 minutes and can be completed on your phone or a computer.',
    },
    {
      image: '../img/swipe.png',
      caption: 'Thumbs up or thumbs down to answer simple questions. Remember, there are no wrong or right answers!',
    },
    {
      image: '/high-five.png',
      caption:
        'The results will help us get to know you better. We will send you the results too, so you can understand and promote your strengths when applying for roles.',
    },
  ];

  const [leftOffUrl, setLeftOffUrl] = useState<string>();
  const { answers, userLoading, company, companyLoading, jobRoleId } = useUser();
  const { questions, questionLoading } = useGetQuestions({ companyId: company?.id || '', jobRoleId });

  useEffect(() => {
    const allAnswers = allQuestionsAnswered(questions, answers);
    const defaultLeftOffUrl = whereLeftOff(allAnswers, questions, answers, company?.id || '');

    if (defaultLeftOffUrl) {
      setLeftOffUrl(defaultLeftOffUrl);
    }
  }, [answers, questions, company]);

  if (userLoading || companyLoading || questionLoading) return <Loading />;

  return (
    <div className="flex flex-col items-center justify-around text-center text-white h-5/6 pt-6">
      <div className="ps-3">
        <div className="text-white text-xl text-center sm:text-display-sm font-semibold leading-xl sm:leading-display-sm mb-1">TeamFit {company?.name}</div>
      </div>
      <div className="z-10 mb-2">
        <Carousel items={carousel} />
      </div>
      {!leftOffUrl && (
        <button type="button" className="bg-yellow-500 text-black 	p-2	uppercase rounded-lg font-semibold text-md " onClick={() => nextStep()}>
          Get started
        </button>
      )}
      {leftOffUrl && <LeftOffDialog key={1} setLeftOffUrl={setLeftOffUrl} leftOffUrl={leftOffUrl} companyId={company?.id || ''} />}
    </div>
  );
};

export { AppInstructions };
