import { InfoBoxDescription } from 'pages/ResultsOverview/types';

interface ResultsInfoListProps {
  resultsData: {
    name: string;
    value: number | null;
    fill: string;
    description: InfoBoxDescription;
  }[];
}

const ResultsInfoList = ({ resultsData }: ResultsInfoListProps) => (
  <div className="w-full h-full space-y-4 md:space-y-3">
    {resultsData.map((data, index) => {
      const { fill, value } = data;
      const isValueNan = Number.isNaN(value) || value === null;
      const barWidth = isValueNan ? 0 : value;

      return (
        <div key={index} className="flex flex-col items-center space-y-0 text-sm md:space-y-3 md:space-x-4 md:flex-row">
          {/* Right-Aligned Trait Name */}
          <div className="w-full text-lg font-semibold text-center text-white md:w-48 md:text-right md:text-md">{data.name}</div>

          {/* Bar Container */}
          <div className="box-content md:w-1/2 sm:w-4/5 w-full h-4 md:h-6 p-[2px] rounded-full bg-infoBar">
            <div
              className="flex items-center h-4 px-2 font-bold text-white rounded-full md:h-6"
              style={{
                width: `${barWidth}%`,
                backgroundColor: fill,
                display: `${isValueNan || value === 0 ? 'none' : ''}`,
              }}
            />
          </div>
        </div>
      );
    })}
  </div>
);

export default ResultsInfoList;
